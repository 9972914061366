export const routerPaths = {
  main: '/',
  signIn: '/sign-in',
  signUp: '/sign-up',
  account: '/account',
  support: '/support',
  accountSubscription: '/account/subscription',
  accountChangePassword: '/account/change-password',
  accountChangePhone: '/account/change-phone',
  accountRefer: '/account/refer',
  profile: (slug: string) => `/profile/${slug}`,
  mediaList: '/media',
  media: (slug: string) => `/media/${slug}`,
} as const;